import { makeAutoObservable, runInAction } from 'mobx';
import snakeCaseKeys from 'snakecase-keys';

export default class Customer {

    id;
    utility;
    bills = [];
    webhooks = [];

    constructor(json) {
        Object.keys(json).forEach(key => {
            this[key] = json[key];
        });
        makeAutoObservable(this);
    }

    async finishedOnboarding() {
        const response = await fetch("https://bayou.energy/api/public/utilities");
        if (!response.ok) {
            throw new Error(`Error loading data from Bayou`);
        }
        const jsonData = await response.json();
        const utilities = jsonData["utilities"];
        this.webhooks.push({
            event: "customer_has_filled_credentials",
            object: JSON.stringify({
                "id": this.id,
                "external_id": null,
                "utility": this.utility,
                "has_filled_credentials": true,
                "has_filled_credentials_on": new Date(Date.now()).toISOString(),
                "utility_data_velocity": utilities.find(utility => utility.symbol === this.utility)["data_velocity"],
            }),
        });
    }

    billsReady(data) {
        this.webhooks.push({
            event: "bills_ready",
            object: JSON.stringify(snakeCaseKeys(data, {deep: true})),
        });
    }

    intervalsReady(data) {
        this.webhooks.push({
            event: "intervals_ready",
            object: JSON.stringify(snakeCaseKeys(data, {deep: true})),
        });
    }

    newBill(data) {
        this.webhooks.push({
            event: "new_bill",
            object: JSON.stringify(snakeCaseKeys(data, {deep: true})),
        })
    }



}