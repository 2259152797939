import React, { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import territories from './territories.json';
import states from './states-10m.json';

const stateNames = {
    "Alabama": "AL",
    "Alaska": "AK",
    "American Samoa": "AS",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "District Of Columbia": "DC",
    "Federated States Of Micronesia": "FM",
    "Florida": "FL",
    "Georgia": "GA",
    "Guam": "GU",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Marshall Islands": "MH",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Northern Mariana Islands": "MP",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Palau": "PW",
    "Pennsylvania": "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virgin Islands": "VI",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
}

const utilitiesByState = {
    "MA": ['eversource', 'national_grid_massachusetts'],
    "RI": ['rhode_island_energy'],
    "NH": ['central_maine_power', 'unitil', 'eversource'],
    "ME": ['versant_power', 'central_maine_power'],
    "VT": ['green_mountain_power'],
    "CT": ['the_united_illuminating_company', 'eversource'],
    "NJ": ['atlantic_city_electric', 'jersey_central_power_and_light', 'pseg_new_jersey'],
    "NY": ['national_grid_new_york', 'penelec', 'pseg_long_island', 'central_hudson', 'con_edison', 'nyseg', 'rochester_gas_and_electric', 'orange_and_rockland'],
    "PA": ['duquesne_light', 'peco', 'west_penn_power', 'penelec', 'penn_power', 'metropolitan_edison', 'ppl_electric'],
    "DE": ['delmarva_power'],
    "DC": ['pepco'],
    "VA": ['kentucky_utilities', 'dominion_energy', 'appalachian_power', 'pepco'],
    "MD": ['baltimore_gas_and_electric', 'delmarva_power', 'pepco', 'potomac_edison'],
    "WV": ['mon_power', 'appalachian_power'],
    "NC": ['dominion_energy', 'duke_energy'],
    "SC": ['dominion_energy', 'duke_energy'],
    "GA": ['georgia_power', 'jackson_electric_member_corp'],
    "FL": ['duke_energy', 'jea', 'orlando_utilities_commission', 'florida_power_and_light', 'tampa_electric'],
    "AL": ['alabama_power'],
    "TN": ['kentucky_utilities', 'middle_tennessee_electric', 'nashville_electric_service', 'entergy'],
    "MS": ['entergy', 'mississippi_power'],
    "KY": ['kentucky_utilities', 'kentucky_power', 'duke_energy'],
    "OH": ['toledo_edison', 'ohio_edison', 'duke_energy', 'the_illuminating_company', 'american_electric_power_ohio', 'aes_ohio'],
    "IN": ['indiana_michigan_power', 'duke_energy', 'aes_indiana'],
    "MI": ['consumers_energy', 'dte_energy', 'indiana_michigan_power', 'we_energies'],
    "IA": ['alliant_energy', 'midamerican_energy'],
    "WI": ['alliant_energy', 'wisconsin_public_service', 'madison_gas_and_electric', 'we_energies'],
    "MN": ['xcel_energy'],
    "SD": ['midamerican_energy', 'xcel_energy'],
    "ND": ['xcel_energy'],
    "NE": [],
    "MT": ['avista', 'northwestern_energy'],
    "IL": ['commonwealth_edison', 'ameren', 'midamerican_energy'],
    "MO": ['ameren', 'evergy'],
    "KS": ['evergy'],
    "LA": ['southwestern_electric_power_company', 'entergy', 'cleco_power'],
    "AR": ['southwestern_electric_power_company', 'entergy', 'oklahoma_gas_and_electric'],
    "OK": ['public_service_company_of_oklahoma', 'oklahoma_gas_and_electric'],
    "TX": ['public_service_company_of_new_mexico', 'southwestern_electric_power_company', 'cps', 'american_electric_power_texas', 'center_point_energy', 'entergy', 'austin_utilities', 'pedernales_electric_coop', 'coserv', 'el_paso_electric', 'xcel_energy'],
    "CO": ['colorado_springs_utilities', 'xcel_energy', 'united_power'],
    "WY": ['northwestern_energy', 'rocky_mountain_power'],
    "ID": ['idaho_power', 'avista', 'rocky_mountain_power'],
    "UT": ['rocky_mountain_power'],
    "AZ": ['tucson_electric_power', 'salt_river_project', 'arizona_public_service'],
    "NM": ['public_service_company_of_new_mexico', 'el_paso_electric', 'xcel_energy'],
    "NV": ['nv_energy'],
    "CA": ['modesto_irrigation_district', 'rocky_mountain_power', 'los_angeles_department_of_water_and_power', 'sacramento_municipal_utility_district', 'pacific_gas_and_electric', 'liberty_utilities', 'southern_california_edison', 'san_diego_gas_and_electric'],
    "OR": ['idaho_power', 'portland_general_electric', 'rocky_mountain_power'],
    "WA": ['puget_sound_energy', 'avista', 'rocky_mountain_power', 'seattle_city_light'],
}

const UtilityMap = ({utilities, highlightedUtility, setFilteredUtilities, bluechipUtilities, betaUtilities}) => {
    const showState = geo => (e) => {
        const stateName = geo.properties.name;
        setFilteredUtilities(utilitiesByState[stateNames[stateName]]
            .filter(utility => !['jackson_electric_member_corp', 'jea', 'pedernales_electric_coop', 'coserv'].includes(utility))
            .map(utility => utilities.find(u => u.symbol === utility)));
        //setTooltip({ top: e.pageY, left: e.pageX, utilities: utilitiesByState[stateNames[stateName]] });
    };
    return (
        <>
            <ComposableMap projection="geoAlbersUsa">
                <pattern id="bluechipSelected" patternUnits="userSpaceOnUse" width="8" height="8">
                    <rect width="100%" height="100%" fill="#111B4C" />
                    <rect width="100%" height="100%" fill="#ffffff" fillOpacity="0.3" />
                    <path d="M-2,2 l4,-4
                            M0,8 l8,-8
                            M6,10 l4,-4"
                            style={{stroke: "#111B4C", "strokeWidth": "2"}} />
                </pattern>
                <pattern id="betaSelected" patternUnits="userSpaceOnUse" width="8" height="8">
                    <rect width="100%" height="100%" fill="#E4B644" />
                    <rect width="100%" height="100%" fill="#000000" fillOpacity="0.3" />
                    <path d="M-2,2 l4,-4
                            M0,8 l8,-8
                            M6,10 l4,-4"
                            style={{stroke: "#E4B644", "strokeWidth": "2"}} />
                </pattern>

                <Geographies geography={territories}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            let fill;
                            if (bluechipUtilities.includes(geo.properties.utility)) {
                                fill = (highlightedUtility === geo.properties.utility) ? "url('#bluechipSelected')": "#111B4C";
                            } else if (betaUtilities.includes(geo.properties.utility)) {
                                fill = (highlightedUtility === geo.properties.utility) ? "url('#betaSelected')" : "#E4B644";
                            } else {
                                fill = "#e2e6f0";
                            }
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    stroke="#F7F9FC"
                                    strokeWidth="0"
                                    fill={fill}
                                />
                            )
                        })
                    }
                </Geographies>
                <Geographies geography={states}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            return <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                stroke="#F7F9FC"
                                strokeWidth="1"
                                fillOpacity={0}
                                onClick={showState(geo)}
                                className="svg-state"
                                onMouseEnter={(e) => {
                                    e.target.parentElement.appendChild(e.target);
                                    e.target.classList.add("svg-state-hover");
                                }}
                                onMouseLeave={(e) => {
                                    e.target.classList.remove("svg-state-hover");
                                }}
                            />
                        })
                    }
                </Geographies>
            </ComposableMap>
        </>
    )
};

export default UtilityMap;