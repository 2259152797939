import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Utilities from './Utilities';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import Quickstart from './Quickstart';
import Demo from './Demo';

const plusTenMinutes = new Date().getTime() + 10 * 60 * 1000;
const timeString = new Date(plusTenMinutes).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', timeZoneName: 'longGeneric'});
document.getElementById("timestamp_plus_ten").innerText = timeString;

ReactDOM.render(
  <React.StrictMode>
    <Quickstart />
  </React.StrictMode>,
  document.getElementById("quickstart")
);

ReactDOM.render(
  <React.StrictMode>
    <Utilities />
  </React.StrictMode>,
  document.getElementById("utilities")
);

ReactDOM.render(
  <React.StrictMode>
    <Demo />
  </React.StrictMode>,
  document.getElementById("demo")
);

reportWebVitals(sendToVercelAnalytics);
