import React, { useState, useEffect } from 'react';
import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import javascript from 'highlight.js/lib/languages/javascript';
import csharp from 'highlight.js/lib/languages/csharp';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('csharp', csharp);

const Quickstart = () => {
    function linkify(inputText) {
        var replacedText, replacePattern;    
        replacePattern = /((?<!;)(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern, '<a href="$1" target="_blank">$1</a>');   
        return replacedText;
    }
    const languages = {
        "python": "Python",
        "ruby": "Ruby",
        "javascript": "JavaScript",
        "csharp": "C#",
    }
    const data = {
        "python": [
`# Manage your API keys at https://bayou.energy/dashboard/keys
# API reference: https://docs.bayou.energy/v2.0/reference/authentication
bayou_api_key = "live_1_13a720.....3cc858f1c673c48d"
​
# Create a new customer.
# API reference: https://docs.bayou.energy/v2.0/reference/post_customers
customer = requests.post(f"https://bayou.energy/api/v2/customers", json={
    # Speculoos is Bayou's fake utility for testing
    "utility": "speculoos_power",
    # https://docs.bayou.energy/v2.0/reference/utility-support
}, auth=(bayou_api_key, '')).json()`,

`# onboarding_link is an attribute of Bayou's Customer model that provides a URL for customers to connect their utility account
print(f"Fill the customer credentials using the following link: {customer['onboarding_link']}")

# You can use the following credentials to load test data:
# Email: iamvalid@bayou.energy
# Password: validpassword`,

`# Get all bills for this specific customer.
# API reference: https://docs.bayou.energy/v2.0/reference/get_customers-customer-id-bills
bills = requests.get(f"https://bayou.energy/api/v2/customers/{customer['id']}/bills", auth=(bayou_api_key, '')).json()
​
# Get all intervals for this specific customer.
# API reference: https://docs.bayou.energy/v2.0/reference/get_customers-customer-id-intervals
intervals = requests.get(f"https://bayou.energy/api/v2/customers/{customer['id']}/intervals", auth=(bayou_api_key, '')).json()
​
`
        ],
        "ruby": [
`# Manage your API keys at https://bayou.energy/dashboard/keys
# API reference: https://docs.bayou.energy/v2.0/reference/authentication
bayou_api_key = "live_1_13a720.....3cc858f1c673c48d"

# Create a new customer.
# API reference: https://docs.bayou.energy/v2.0/reference/post_customers
uri = URI("https://bayou.energy/api/v2/customers")
http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = true

request = Net::HTTP::Post.new(uri.path, 'Content-Type' => 'application/json')
request.basic_auth(bayou_api_key, '')

# Speculoos is Bayou's fake utility for testing
request.body = { "utility" => "speculoos_power" }.to_json
# https://docs.bayou.energy/v2.0/reference/utility-support

customer = JSON.parse(http.request(request).body)`,

`# onboarding_link is an attribute of Bayou's Customer model that provides a URL for customers to connect their utility account
puts "Fill the customer credentials using the following link: #{customer['onboarding_link']}"

# You can use the following credentials to load test data:
# Email: iamvalid@bayou.energy
# Password: validpassword`,

`# Get all bills for this specific customer.
bills_uri = URI("https://bayou.energy/api/v2/customers/#{customer_id}/bills")
bills_request = Net::HTTP::Get.new(bills_uri)
bills_request.basic_auth(bayou_api_key, '')
bills_response = Net::HTTP.start(bills_uri.hostname, bills_uri.port, use_ssl: true) do |http|
  http.request(bills_request)
end
bills = JSON.parse(bills_response.body)

# Get all intervals for this specific customer.
intervals_uri = URI("https://bayou.energy/api/v2/customers/#{customer_id}/intervals")
intervals_request = Net::HTTP::Get.new(intervals_uri)
intervals_request.basic_auth(bayou_api_key, '')
intervals_response = Net::HTTP.start(intervals_uri.hostname, intervals_uri.port, use_ssl: true) do |http|
  http.request(intervals_request)
end
intervals = JSON.parse(intervals_response.body)`,
        ],
        "javascript": [
`// Manage your API keys at https://bayou.energy/dashboard/keys
// API reference: https://docs.bayou.energy/v2.0/reference/authentication
const bayouApiKey = "live_1_13a720.....3cc858f1c673c48d";

// Create a new customer. API reference: https://docs.bayou.energy/v2.0/reference/post_customers
const response = await fetch("https://bayou.energy/api/v2/customers", {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': \`Basic \${btoa(\`\${bayou_api_key}:\`)}\`,
    },
    body: JSON.stringify({
        // Speculoos is Bayou's fake utility for testing
        utility: "speculoos_power"
        // https://docs.bayou.energy/v2.0/reference/utility-support
    })
});
const customer = await response.json();`,

`// onboarding_link is an attribute of Bayou's Customer model that provides a URL for customers to connect their utility account
console.log(\`Fill the customer credentials using the following link: \${customer.onboarding_link}\`);

// You can use the following credentials to load test data:
// Email: iamvalid@bayou.energy
// Password: validpassword`,

`// Get all bills for this specific customer.
// API reference: https://docs.bayou.energy/v2.0/reference/get_customers-customer-id-bills
const billsResponse = await fetch(\`https://bayou.energy/api/v2/customers/\${customer.id}/bills\`, {
  method: 'GET',
  headers: {
    'Authorization': \`Basic \${btoa(\`\${bayou_api_key}:\`)}\`,
    'Content-Type': 'application/json'
  }
})
const bills = billsResponse.json();

// Get all intervals for this specific customer.
// API reference: https://docs.bayou.energy/v2.0/reference/get_customers-customer-id-intervals
const intervalsResponse = await fetch(\`https://bayou.energy/api/v2/customers/\${customer.id}/intervals\`, {
  method: 'GET',
  headers: {
    'Authorization': \`Basic \${btoa(\`\${bayou_api_key}:\`)}\`,
    'Content-Type': 'application/json'
  }
})
const intervals = intervalsResponse.json();`,
        ],
        "csharp": [
`// Manage your API keys at https://bayou.energy/dashboard/keys
// API reference: https://docs.bayou.energy/v2.0/reference/authentication
string bayouApiKey = "live_1_13a720.....3cc858f1c673c48d";

// Create a new customer. API reference: https://docs.bayou.energy/v2.0/reference/post_customers
using (HttpClient client = new HttpClient())
{
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
    client.DefaultRequestHeaders.Add("Authorization", "Basic " + Convert.ToBase64String(Encoding.UTF8.GetBytes(bayouApiKey + ":")));
    // Speculoos is Bayou's fake utility for testing
    string jsonInputString = "{\"utility\":\"speculoos_power\"}";
    // https://docs.bayou.energy/v2.0/reference/utility-support
    HttpResponseMessage response = await client.PostAsync("https://bayou.energy/api/v2/customers", new StringContent(jsonInputString, Encoding.UTF8, "application/json"));
    string responseContent = await response.Content.ReadAsStringAsync();
    dynamic customer = Newtonsoft.Json.JsonConvert.DeserializeObject(responseContent);
}`,

`// onboarding_link is an attribute of Bayou's Customer model that provides a URL for customers to connect their utility account
Console.WriteLine($"Fill the customer credentials using the following link: {customer.onboarding_link}");

// You can use the following credentials to load test data:
// Email: iamvalid@bayou.energy
// Password: validpassword
`,

`using (HttpClient client = new HttpClient())
{
    client.DefaultRequestHeaders.Add("Authorization", "Basic " + Convert.ToBase64String(Encoding.UTF8.GetBytes(bayouApiKey + ":")));
    var bills = await client.GetStringAsync($"https://bayou.energy/api/v2/customers/{customer.id}/bills");
    var intervals = await client.GetStringAsync($"https://bayou.energy/api/v2/customers/{customer.id}/intervals");
}`,
        ]
    }
    const activateLanguage = (language) => {
      {[1,2,3].forEach((step) => {
        new window.bootstrap.Tab(document.querySelector('#snippet'+language+'_'+step+'-tab')).show()
      })}      
    }
    return (
        <>
        <div className="col-lg-6 col-12">
    <ul className="nav nav-tabs step-box-tab mb-0" id="myTab1" role="tablist">
          <li className="nav-item" role="presentation">
            <div className="nav-link active" id="code1-tab" data-bs-toggle="tab" data-bs-target="#code1" type="button" role="tab" aria-controls="code1" aria-selected="true">
                <div className="step-box">
                    <div className="step-number">01</div>
                    <p>Create your customer</p>
                </div>
            </div>
          </li>
          <li className="nav-item" role="presentation">
            <div className="nav-link" id="code2-tab" data-bs-toggle="tab" data-bs-target="#code2" type="button" role="tab" aria-controls="code2" aria-selected="false">
                <div className="step-box">
                    <div className="step-number">02</div>
                    <p>Let your customer share their credentials</p>
                </div>
            </div>
          </li>
          <li className="nav-item" role="presentation">
            <div className="nav-link" id="code3-tab" data-bs-toggle="tab" data-bs-target="#code3" type="button" role="tab" aria-controls="code3" aria-selected="false">
                <div className="step-box">
                    <div className="step-number">03</div>
                    <p>Get bill and interval data within 60 seconds</p>
                </div>
            </div>
          </li>
    </ul>
</div>
<div className="col-lg-6">
    <div className="code-dev-box">
        <div className="tab-content">
            {[1,2,3].map((step) => 
              <div key={step} className={"tab-pane fade" + (step === 1 ? " show active" : "")} id={"code"+step} role="tabpanel" aria-labelledby={"code"+step+"-tab"}>
                  <div className="code-tab-div">
                      <ul className="nav nav-tabs img-tabs mb-0" id="myTab1" role="tablist">
                          {Object.keys(languages).map((language) => 
                            <li className="nav-item" role="presentation" key={language}>
                                <button className={"nav-link" + (language === "python" ? " active" : "")} id={"snippet"+language+"_"+step+"-tab"} data-bs-toggle="tab" data-bs-target={"#snippet"+language+"_"+step} onClick={() => activateLanguage(language)} type="button" role="tab" aria-controls="snippet1_1" aria-selected="true">{languages[language]}</button>
                            </li>
                          )}
                    </ul>
                  </div>
                  <div className="tab-content code-container">
                    {Object.keys(languages).map((language) => 
                      <div key={language} className={"tab-pane code-square-pad fade pre" + (language === "python" ? " show active" : "")} id={"snippet"+language+"_"+step} role="tabpanel" aria-labelledby={"snippet"+language+"_"+step+"-tab"}>
                          <code dangerouslySetInnerHTML={{__html: hljs.highlight(data[language][step - 1], {language: language}).value}} className={["javascript", "csharp"].includes(language) ? "small-code" : ""}></code>
                      </div>
                    )}
                </div>
              </div>
            )}
        </div>
    </div>
</div></>)

};

export default Quickstart;