import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js'
import UtilityMap from './UtilityMap';
import UtilityList from './UtilityList';

const Utilities = () => {
    const [utilities, setUtilities] = useState([]);
    const [highlightedUtility, setHighlightedUtility] = useState(null);
    const [filteredUtilities, setFilteredUtilities] = useState([]);
    const [time, setTime] = useState("today");

    const currentBluechipUtilities = utilities.filter(utility => utility["data_velocity"] === "Instant");
    const currentBetaUtilities = utilities.filter(utility => utility["data_velocity"] !== "Instant" && utility["supports_credential_checks"]);

    const q1 = ['aes_ohio', 'american_electric_power_ohio', 'arizona_public_service', 'atlantic_city_electric', 'austin_utilities', 'baltimore_gas_and_electric', 'center_point_energy', 'central_maine_power', 'consumers_energy', 'delmarva_power', 'dominion_energy', 'el_paso_electric', 'florida_power_and_light', 'georgia_power', 'hawaiian_electric', 'jersey_central_power_and_light', 'los_angeles_department_of_water_and_power', 'nv_energy', 'nyseg', 'ohio_edison', 'pacific_power', 'peco', 'penelec', 'pepco', 'portland_general_electric', 'potomac_edison', 'pseg_long_island', 'pseg_new_jersey', 'public_service_company_of_new_mexico', 'rochester_gas_and_electric', 'sacramento_municipal_utility_district', 'southern_california_edison', 'southern_california_gas'].map((item) => utilities.find(utility => utility["symbol"] === item)).filter((item) => item !== undefined && !currentBluechipUtilities.includes(item));
    const q2 = ['aes_indiana', 'alabama_power', 'alliant_energy', 'appalachian_power', 'cps', 'dte_energy', 'duquesne_light', 'evergy', 'green_mountain_power', 'gulf_power', 'indiana_michigan_power', 'kentucky_power', 'kentucky_utilities', 'liberty_utilities', 'madison_gas_and_electric', 'memphis_light_gas_and_water', 'metropolitan_edison', 'midamerican_energy', 'middle_tennessee_electric', 'mon_power', 'nashville_electric_service', 'northwestern_energy', 'orlando_utilities_commission', 'penn_power', 'ppl_electric', 'rhode_island_energy', 'rocky_mountain_power', 'salt_river_project', 'san_diego_gas_and_electric', 'southwestern_electric_power_company', 'tampa_electric', 'the_illuminating_company', 'the_united_illuminating_company', 'toledo_edison', 'united_power', 'unitil', 'versant_power', 'we_energies', 'west_penn_power', 'wisconsin_public_service'].map((item) => utilities.find(utility => utility["symbol"] === item)).filter((item) => item !== undefined && !currentBluechipUtilities.includes(item));

    const bluechipUtilitiesMarch = [...currentBluechipUtilities, ...q1];
    const betaUtilitiesMarch = currentBetaUtilities.filter((item) => !q1.includes(item));

    const bluechipUtilitiesDecember = [...currentBluechipUtilities, ...q1, ...q2];
    const betaUtilitiesDecember = currentBetaUtilities.filter((item) => !q1.includes(item)).filter((item) => !q2.includes(item));

    const returnStats = (bluechip, beta) => {
        return {
            bluechipUtilities: bluechip.map(utility => utility["symbol"]),
            bluechip: bluechip.length,
            bluechipPopulation: Math.round((bluechip.map(utility => utility["population"]).reduce((a, b) => a + b, 0) / 140000000) * 100),
            betaUtilities: beta.map(utility => utility["symbol"]),
            beta: beta.length,
            betaPopulation: Math.round((beta.map(utility => utility["population"]).reduce((a, b) => a + b, 0) / 140000000) * 100)
        }
    }

    const stats = {
        today: returnStats(currentBluechipUtilities, currentBetaUtilities),
        march: returnStats(bluechipUtilitiesMarch, betaUtilitiesMarch),
        december: returnStats(bluechipUtilitiesDecember, betaUtilitiesDecember)
    }

    const bluechipUtilities = stats[time]["bluechipUtilities"];
    const betaUtilities = stats[time]["betaUtilities"];

    useEffect(() => {
        const fetchUtilities = async () => {
            const response = await fetch("https://bayou.energy/api/public/utilities");
            if (!response.ok) {
                throw new Error(`Error loading data from Bayou`);
            }
            const jsonData = await response.json();
            const utilities = jsonData["utilities"];
            setUtilities(utilities.filter(utility => utility["symbol"] !== "speculoos_power"));
        };
        fetchUtilities();
    }, []);
    if (utilities === null) {
        return <div>Loading...</div>
    }
    return (
        <div className="row">
            <div className="col-xl-6 col-lg-7 col-12">
                <h2 className="title">Rapidly expanding utility support</h2>
                <p>You get data instantly from {stats["today"]["bluechip"]} utilities today and from {stats["december"]["bluechipPopulation"]}% of US utility customers by December
                    2024.<br />Bayou can add new utilities within one week.</p>
            </div>
            <div className="w-100"></div>
            <div className="col-lg-8 col-12">
                <a href="https://docs.bayou.energy/reference/utility-support"
                    className="btn theme-btn mt-3 me-2">Utility Support
                    <span className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path d="M5 12H18M12 5L19 12L12 19" stroke="currentColor" strokeWidth="1.5"
                                strokeLinecap="square" />
                        </svg>
                    </span>
                </a>
                <a href="https://calendly.com/jamesbayouenergy/30min" className="btn theme-btn border-btn mt-3">Request
                    a new utility
                    <span className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path d="M5 12H18M12 5L19 12L12 19" stroke="currentColor" strokeWidth="1.5"
                                strokeLinecap="square" />
                        </svg>
                    </span>
                </a>
            </div>
            <div className="col-12 pt-lg-3 pt-0 mt-4">
                <div className="light-padding-box light-bg">
                    <ul className="nav nav-tabs border-bottom mb-0 mt-0" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (time === "today" ? " active" : "")} id="support1-tab" onClick={() => setTime("today")} type="button" role="tab" aria-controls="support1" aria-selected="true">Current</button>
                        </li>
                        {/*<li className="nav-item" role="presentation">
                            <button className={"nav-link" + (time === "march" ? " active" : "")} id="support2-tab" onClick={() => setTime("march")} type="button" role="tab" aria-controls="support2" aria-selected="false">September 2024</button>
                        </li>*/}
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (time === "december" ? " active" : "")} id="support3-tab" onClick={() => setTime("december")} type="button" role="tab" aria-controls="support3" aria-selected="false">December 2024</button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane tab-pane-pad fade show active" role="tabpanel" aria-labelledby="support1-tab">
                            <div className="row gx-xl-5">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card utility-support-card">
                                                <div className="card-body">
                                                    <img src="img/flash_icon.png" className='pull-left me-3' width="46" />

                                                    <span className="utility-support-main">{stats[time]["bluechip"]}</span> utilities ({stats[time]["bluechipPopulation"]}% population)<br />
                                                    <span className="utility-support-sub">With instant support</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {time == "today" &&
                                                <div className="card utility-support-card">
                                                    <div className="card-body">
                                                        <img src="img/beta_icon.png" className='pull-left me-3' width="46" />
                                                        <span className="utility-support-main">{stats[time]["beta"]}</span> utilities ({stats[time]["betaPopulation"]}% population)<br />
                                                        <span className="utility-support-sub">With beta support</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="img-fluid">
                                        <UtilityMap utilities={utilities} highlightedUtility={highlightedUtility} setFilteredUtilities={setFilteredUtilities} bluechipUtilities={bluechipUtilities} betaUtilities={betaUtilities} />
                                        <span className="circle-instant"></span> Utilities with instant support
                                        <span className="circle-beta"></span> Beta utilities
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-lg-0 mt-4">
                                    <div className="utility-support-box">
                                        <UtilityList utilities={utilities} filteredUtilities={filteredUtilities} setFilteredUtilities={setFilteredUtilities} highlightedUtility={highlightedUtility} setHighlightedUtility={setHighlightedUtility} bluechipUtilities={bluechipUtilities} betaUtilities={betaUtilities} q1={q1} q2={q2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Utilities;









