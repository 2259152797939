import { makeAutoObservable, runInAction } from 'mobx';

export default class FakeCustomer {

    isLoading = false;
    id = null;
    email = null;
    firstName = null;
    lastName = null;
    phoneNumber = null;
    accountNumber = null;
    utility = "speculoos_power";
    hasFilledCredentials = false;
    onboardingLink = null;
    bills = [];
    webhooks = [];
    transferReportLines = [];
    pusherChannel;
    nextWebhookId;
    billsFrom;
    billsTo;

    constructor() {
        makeAutoObservable(this);
        this.nextWebhookId = 9463;
        this.id = 1234;
        let nextBillId = 8291;
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth());

        for (let i = 12; i > 0; i--) {
            const currentStart = new Date(lastMonth);
            currentStart.setMonth(lastMonth.getMonth() - i);
            currentStart.setDate(1);

            const currentEnd = new Date(lastMonth);
            currentEnd.setMonth(lastMonth.getMonth() - i + 1);
            currentEnd.setDate(0);

            this.bills.push({
                id: nextBillId++,
                billing_period_from: currentStart.toISOString().split('T')[0],
                billing_period_to: currentEnd.toISOString().split('T')[0],
                outstanding_balance: 0,
                electricity_consumption: 111992,
                electricity_amount: 15365,
                delivery_charge: 7068,
                supply_charge: 8297,
                community_solar_bill_credit: 13829,
                gas_consumption: 9,
                gas_consumption_unit: "ccf",
                gas_amount: 6300,
                total_amount: 1537,
            });

        }
        this.billsFrom = this.bills[0].billing_period_from;
        this.billsTo = this.bills[this.bills.length - 1].billing_period_to;
    }

    async loadInfo() {
        setTimeout(() => {
            this.initTime = new Date(Date.now());
            this.webhooks.push({
                event: "customer_has_filled_credentials",
                object: JSON.stringify({
                    "id": this.id,
                    "external_id": null,
                    "utility": "speculoos_power",
                    "has_filled_credentials": true,
                    "has_filled_credentials_on": new Date(Date.now()).toISOString(),
                    "utility_data_velocity": "Instant"
                }),
                httpStatusCode: 200,
                createdAt: new Date(Date.now()),
                id: this.nextWebhookId++
            })
        }, 200);
        this.bills.forEach((bill, i) => {
            setTimeout(() => {
                this.webhooks.push({
                    event: "new_bill",
                    object: JSON.stringify({
                        "id": bill.id,
                        "external_id": null,
                        "status": "unlocked",
                        "customer_id": this.id,
                        "customer_external_id": null,
                        "past_due": false,
                        "billed_on": bill.billing_period_to,
                        "billing_period_from": bill.billing_period_from,
                        "billing_period_to": bill.billing_period_to,
                        "utility": "speculoos_power",
                        "account_number": "498154477303083",
                        "outstanding_balance": bill.outstanding_balance,
                        "electricity_consumption": bill.electricity_consumption,
                        "electricity_amount": bill.electricity_amount,
                        "delivery_charge": bill.delivery_charge,
                        "supply_charge": bill.supply_charge,
                        "community_solar_bill_credit": bill.community_solar_bill_credit,
                        "gas_consumption": bill.gas_consumption,
                        "gas_consumption_unit": bill.gas_consumption_unit,
                        "gas_amount": bill.gas_amount,
                        "total_amount": bill.total_amount,
                        "meters": [
                            {
                                "id": "E4490291",
                                "type": "Electric",
                                "tariff": "Residential 123",
                                "billing_period_to": bill.billing_period_from,
                                "billing_period_from": bill.billing_period_to,
                                "consumption": bill.electricity_consumption
                            },
                            {
                                "id": "G3048593",
                                "type": "Gas",
                                "tariff": "Residential XYZ",
                                "billing_period_to": bill.billing_period_from,
                                "billing_period_from": bill.billing_period_to,
                                "consumption": bill.gas_consumption
                            }
                        ],
                        "file_url": "https://private.bayou.energy/bills/12345.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=XYZ&X-Amz-Date=20220701T000000Z&X-Amz-Expires=1800&X-Amz-Signature=ABCEF0123456&X-Amz-SignedHeaders=host"
                    }),
                    httpStatusCode: 200,
                    createdAt: new Date(Date.now()),
                    id: this.nextWebhookId++
                })
            }, 2000 + i * 200);
        });
        setTimeout(() => {
            this.webhooks.push({
                event: "bills_ready",
                object: JSON.stringify({
                    "id": 109,
                    "external_id": null,
                    "bills_parsed": this.bills.map(bill => ({
                        "id": bill.id,
                        "external_id": null,
                        "status": "unlocked",
                        "customer_id": this.id,
                        "customer_external_id": null,
                        "past_due": false,
                        "billed_on": bill.billing_period_to,
                        "billing_period_from": bill.billing_period_from,
                        "billing_period_to": bill.billing_period_to,
                        "utility": "speculoos_power",
                        "account_number": "498154477303083",
                        "outstanding_balance": bill.outstanding_balance,
                        "electricity_consumption": bill.electricity_consumption,
                        "electricity_amount": bill.electricity_amount,
                        "delivery_charge": bill.delivery_charge,
                        "supply_charge": bill.supply_charge,
                        "community_solar_bill_credit": bill.community_solar_bill_credit,
                        "gas_consumption": bill.gas_consumption,
                        "gas_consumption_unit": bill.gas_consumption_unit,
                        "gas_amount": bill.gas_amount,
                        "total_amount": bill.total_amount,
                        "meters": [
                            {
                                "id": "E4490291",
                                "type": "Electric",
                                "tariff": "Residential 123",
                                "billing_period_to": bill.billing_period_from,
                                "billing_period_from": bill.billing_period_to,
                                "consumption": bill.electricity_consumption
                            },
                            {
                                "id": "G3048593",
                                "type": "Gas",
                                "tariff": "Residential XYZ",
                                "billing_period_to": bill.billing_period_from,
                                "billing_period_from": bill.billing_period_to,
                                "consumption": bill.gas_consumption
                            }
                        ],
                        "file_url": "https://private.bayou.energy/bills/12345.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=XYZ&X-Amz-Date=20220701T000000Z&X-Amz-Expires=1800&X-Amz-Signature=ABCEF0123456&X-Amz-SignedHeaders=host"
                    })),
                    "bills_unparsed": [],
                    "available_data": {
                        "account_numbers": [
                            {
                                "id": "498154477303083",
                                "meters": [
                                    {
                                        "id": "E4490291",
                                        "type": "Electric",
                                        "periods": [
                                            {
                                                "period_from": this.billsFrom,
                                                "period_to": this.billsTo
                                            },
                                        ]
                                    },
                                    {
                                        "id": "G3048593",
                                        "type": "Gas",
                                        "periods": [
                                            {
                                                "period_from": this.billsFrom,
                                                "period_to": this.billsTo
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                }),
                httpStatusCode: 200,
                createdAt: new Date(Date.now()),
                id: this.nextWebhookId++
            })
        }, 4600);
        setTimeout(() => {
            this.webhooks.push({
                event: "intervals_ready",
                object: JSON.stringify({
                    "customer_id": 109,
                    "customer_external_id": null,
                    "first_interval_discovered": this.billsFrom + " 00:00:00+00:00",
                    "last_interval_discovered": this.billsTo + " 23:45:00+00:00",
                    "available_data": {
                        "account_numbers": [
                            {
                                "id": "498154477303083",
                                "meters": [
                                    {
                                        "id": "56926",
                                        "type": "electric",
                                        "periods": [
                                            {
                                                "period_from": this.billsFrom,
                                                "period_to": this.billsTo
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                }),
                httpStatusCode: 200,
                createdAt: new Date(Date.now()),
                id: this.nextWebhookId++
            })
        }, 4600);
    }


}